<template>
  <div class="container mt-3 mb-3" v-if="listEnquete.length > 0">
    <div class="row bg-light mt-3 rounded">
      <nav class="nav">
        <div
          class="row mx-auto mb-4"
          v-for="enquete in listEnquete"
          :key="enquete.id"
        >
          <h5 class="mt-3">Enquete:</h5>
          <EnqueteComponentItem :enquete="enquete" :key="enquete.id" />
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import EnqueteService from "../services/enquete.service";
import EnqueteComponentItem from "./EnqueteComponentItem";

export default {
  name: "EnqueteComponent",
  props: [],
  components: { EnqueteComponentItem },
  data: () => ({
    listEnquete: [],
  }),
  mounted() {
    EnqueteService.getAll(localStorage.locale).then((result) => {
      if (result.status < 400) {
        this.listEnquete = result.data;
      }
    });
  },
};
</script>
